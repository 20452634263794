import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BlogTitle from "../components/BlogTitle"

const BlogCard = (node) => {
  return (
  <Link
      to={node.fields.slug}
      style={{
        textDecoration: `none`,
      }}
   >
    <article key={node.id}
     style={{
       padding: `2rem`,
       marginTop: `2rem`,
       marginBottom: `2rem`,
       borderRadius: `16px`,
       boxShadow: `-5px -5px 10px #FFFFFF, 5px 5px 10px #E0E8E8`,
     }}
     >
      <BlogTitle
        frontmatter = {node.frontmatter}
      />
      <div style={{
          margin: `1rem`,
          padding: `1rem`,
        }}
        dangerouslySetInnerHTML={{ __html: node.excerpt }}
      />
      <div style={{
          display: `flex`,
          justifyContent: `flex-end`,
        }}
      >
        <span
          style={{
            textAlign: `right`,
            margin: `1rem`,
            marginLeft: `0`,
            padding: `1rem`,
            borderRadius: `0.5rem`,
            boxShadow: `-5px -5px 10px #FFFFFF, 5px 5px 10px #E0E8E8`,
          }}
        >
         <b>read more...</b>
        </span>
      </div>
    </article>
  </Link>
)}

export default ({ data }) => {
  return (
  <Layout>
    <SEO title="home" />
    <div
      style={{
        maxWidth: `1000px`,
        margin: `1rem`,
      }}
    > 
    {data.allMarkdownRemark.edges.map(({ node }) => (
       BlogCard(node)
    ))}
    </div>
  </Layout>
)};

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          excerpt(format: HTML)
        }
      }
    }
  }
`
