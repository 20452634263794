import React from "react"

const BlogTitle = ({frontmatter}) => {
   const {title, date} = frontmatter
   return (
      <div
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          alignItems: `baseline`,
        }}
      >
        <h3
          style={{
            margin: `1rem`,
            padding: `1rem`,
            borderRadius: `0.5rem`,
            boxShadow: `-5px -5px 10px #FFFFFF, 5px 5px 10px #E0E8E8`,
            flexGrow: 1,
            textAlign: `center`,
          }}
        >
          {title}
        </h3>
        <span
          style={{
            margin: `1rem`,
            padding: `1rem`,
          }}
        >
          {date}
        </span>
      </div>
   )
}

export default BlogTitle
